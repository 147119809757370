.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

img.app-logo {
  max-width: 50px;
}
.gradient-custom-2 {
  background: #fccb90;
  background-image: linear-gradient(45deg, #f49f20, #fd7d3b);
  box-shadow: 8px 8px 8px #ddd;
  border-radius: 20px;
  position: relative;
}

.form-outline .form-control:focus~.form-label {
  transform: translateY(-1rem) translateY(0.1rem) scale(0.8);
}
header {
  max-width: 60px;
}
.navbar-expand-lg .navbar-toggler {
  display: block !important;
}
header .list-group-item span {
  display: none;
  opacity: 0;
}
.activeMenu header {
  max-width: 250px;
}
.activeMenu header .list-group-item span {
  display: inline-block;
  opacity: 1;
}
header .list-group-item {
  padding-left: 10px;
}
.content-height {
  min-height: 600px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
